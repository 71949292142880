import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [2,3];

export const dictionary = {
		"/(app)": [~5,[2]],
		"/admin": [~9,[4]],
		"/admin/admins": [~10,[4]],
		"/admin/shipments": [~11,[4]],
		"/admin/shipments/[id]": [~12,[4]],
		"/admin/submissions": [~13,[4]],
		"/admin/submissions/[id]": [~14,[4]],
		"/admin/users": [~15,[4]],
		"/admin/users/[id]": [~16,[4]],
		"/(guest)/onboard": [7,[3]],
		"/payout/[shipment]/[payout]": [~17],
		"/(guest)/sign-in": [8,[3]],
		"/(app)/submit": [~6]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';